import {PagedDocumentList} from '../models/Document';

export const UPDATE_DOCUMENT_LIST = 'UPDATE_DOCUMENT_LIST';

export interface UpdateDocumentListAction {
  type: typeof UPDATE_DOCUMENT_LIST;
  payload: PagedDocumentList;
}

export type DocumentActionTypes = UpdateDocumentListAction;

import {
  ProcessActionTypes,
  UPDATE_PROCESS_LIST,
} from '../../types/actions/Process.action';
import {PagedProcessList} from '../../types/models/Process';

const INIT_STATE: PagedProcessList = {
  pageNumber: 0,
  pageSize: 30,
  totalCount: 0,
  items: [] = [],
};

const ProcessReducer = (state = INIT_STATE, action: ProcessActionTypes) => {
  switch (action.type) {
    case UPDATE_PROCESS_LIST: {
      return {
        ...state,
        items: action.payload.items,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        totalCount: action.payload.totalCount,
      };
    }
    default:
      return state;
  }
};
export default ProcessReducer;

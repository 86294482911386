import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { AppActions } from '../../types';
import { Dispatch } from 'redux';
import { UPDATE_XFERTABLE_LIST } from 'types/actions/XferTable.action';

export const onRequestXferTables = (body: {
  pageNumber: number;
  pageSize: number;
}) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await jwtAxios.get('XReference/Tables?pageNumber=' + body.pageNumber + "&pageSize=" + body.pageSize);
      dispatch(fetchSuccess());
      dispatch({
        type: UPDATE_XFERTABLE_LIST,
        payload: res.data.result,
      });
    } catch (err) {
      console.log('error!!!!', err.response);
      dispatch(fetchError(err.response));
    }
  };
};


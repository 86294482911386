import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, fetchStart, fetchSuccess} from './Common';
import {AppActions} from '../../types';
import {Dispatch} from 'redux';
import { UPDATE_TRADINGPARTNER_LIST } from '../../types/actions/TradingPartner.action';

export const onRequestTradingPartners = (body: {
  pageNumber: number;
  pageSize: number;
}) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await jwtAxios.get('TradingPartners?pageNumber='+body.pageNumber+"&pageSize="+body.pageSize);     
      dispatch(fetchSuccess());
      dispatch({
        type: UPDATE_TRADINGPARTNER_LIST,
        payload: res.data.result,
      });
    } catch (err) {
      console.log('error!!!!', err.response);
      dispatch(fetchError(err.response));
    }
  };
};


import {TransactionActionTypes, UPDATE_TRANSACTION_LIST} from '../../types/actions/Transaction.action';
import {PagedTransactionList} from '../../types/models/Transaction';

const INIT_STATE:PagedTransactionList = {
    pageNumber: 0,
    pageSize: 30,
    totalCount: 0,
    items: [] = []  
};

const TransactionReducer = (state = INIT_STATE, action: TransactionActionTypes) => {
  switch (action.type) {
    case UPDATE_TRANSACTION_LIST: {
      return {
        ...state,
        items: action.payload.items,
        pageNumber:action.payload.pageNumber,
        pageSize:action.payload.pageSize,
        totalCount:action.payload.totalCount
      };
    }
    default:
      return state;
  }
};
export default TransactionReducer;

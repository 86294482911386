import {PagedCompiledScriptList} from '../models/CompiledScript';

export const UPDATE_COMPILEDSCRIPT_LIST = 'UPDATE_COMPILEDSCRIPT_LIST';

export interface UpdateCompiledScriptListAction {
  type: typeof UPDATE_COMPILEDSCRIPT_LIST;
  payload: PagedCompiledScriptList;
}

export type CompiledScriptActionTypes = UpdateCompiledScriptListAction;

import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, fetchStart, fetchSuccess} from './Common';
import {AppActions} from '../../types';
import {Dispatch} from 'redux';
import {UPDATE_PROCESS_LIST} from '../../types/actions/Process.action';

export const onRequestProcesses = (body: {
  pageNumber: number;
  pageSize: number;
}) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await jwtAxios.get('Processes?pageNumber=' +body.pageNumber +'&pageSize=' +body.pageSize,);
      dispatch(fetchSuccess());
      console.log('Process Response Data:', res.data);
      console.log('Process Response Data Result:', res.data.result);
      dispatch({
        type: UPDATE_PROCESS_LIST,
        payload: res.data.result,
      });
    } catch (err) {
      console.log('error!!!!', err.response);
      dispatch(fetchError(err.response));
    }
  };
};

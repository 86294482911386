import { PagedXferTableList } from '../models/XferTable';

export const UPDATE_XFERTABLE_LIST = 'UPDATE_XFERTABLE_LIST';

export interface UpdateXferTableListAction {
  type: typeof UPDATE_XFERTABLE_LIST;
  payload: PagedXferTableList;
}

export type XferTableActionTypes = UpdateXferTableListAction;

import {PagedProcessList} from '../models/Process';

export const UPDATE_PROCESS_LIST = 'UPDATE_PROCESS_LIST';

export interface UpdateProcessListAction {
  type: typeof UPDATE_PROCESS_LIST;
  payload: PagedProcessList;
}

export type ProcessActionTypes = UpdateProcessListAction;

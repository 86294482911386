import {NotificationsActionTypes, UPDATE_NOTIFICATIONS_LIST} from '../../types/actions/Notifications.action';
import {PagedNotificationsList} from '../../types/models/Notifications';

const INIT_STATE:PagedNotificationsList = {
    pageNumber: 0,
    pageSize: 30,
    totalCount: 0,
    items: [] = []  
};

const NotificationsReducer = (state = INIT_STATE, action: NotificationsActionTypes) => {
  switch (action.type) {
    case UPDATE_NOTIFICATIONS_LIST: {
      return {
        ...state,
        items: action.payload.items,
        pageNumber:action.payload.pageNumber,
        pageSize:action.payload.pageSize,
        totalCount:action.payload.totalCount
      };
    }
    default:
      return state;
  }
};
export default NotificationsReducer;


import {CompiledScriptActionTypes, UPDATE_COMPILEDSCRIPT_LIST} from '../../types/actions/CompiledScript.action';
import {PagedCompiledScriptList} from '../../types/models/CompiledScript';

const INIT_STATE:PagedCompiledScriptList = {
    pageNumber: 0,
    pageSize: 30,
    totalCount: 0,
    items: [] = []  
};

const CompiledScriptReducer = (state = INIT_STATE, action: CompiledScriptActionTypes) => {
  switch (action.type) {
    case UPDATE_COMPILEDSCRIPT_LIST: {
      return {
        ...state,
        items: action.payload.items,
        pageNumber:action.payload.pageNumber,
        pageSize:action.payload.pageSize,
        totalCount:action.payload.totalCount
      };
    }
    default:
      return state;
  }
};
export default CompiledScriptReducer;

import React from 'react';

export const transactionPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/transactions/main',
        component: React.lazy(() => import('./main')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/transactions/:id',
        component: React.lazy(() => import('./detail')),
      },
    ],
  }
];

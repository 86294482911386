import {PagedTransactionList} from '../models/Transaction';

export const UPDATE_TRANSACTION_LIST = 'UPDATE_TRANSACTION_LIST';

export interface UpdateTransactionListAction {
  type: typeof UPDATE_TRANSACTION_LIST;
  payload: PagedTransactionList;
}

export type TransactionActionTypes = UpdateTransactionListAction;

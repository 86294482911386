import {EndpointActionTypes, UPDATE_ENDPOINT_LIST} from '../../types/actions/Endpoint.action';
import {PagedEndpointList} from '../../types/models/Endpoint';

const INIT_STATE:PagedEndpointList = {
    pageNumber: 0,
    pageSize: 30,
    totalCount: 0,
    items: [] = []  
};

const EndpointReducer = (state = INIT_STATE, action: EndpointActionTypes) => {
  switch (action.type) {
    case UPDATE_ENDPOINT_LIST: {
      return {
        ...state,
        items: action.payload.items,
        pageNumber:action.payload.pageNumber,
        pageSize:action.payload.pageSize,
        totalCount:action.payload.totalCount
      };
    }
    default:
      return state;
  }
};
export default EndpointReducer;


import {TradingPartnerActionTypes, UPDATE_TRADINGPARTNER_LIST} from '../../types/actions/TradingPartner.action';
import {PagedTradingPartnerList} from '../../types/models/TradingPartner';

const INIT_STATE:PagedTradingPartnerList = {
    pageNumber: 0,
    pageSize: 30,
    totalCount: 0,
    items: [] = []  
};

const TradingPartnerReducer = (state = INIT_STATE, action: TradingPartnerActionTypes) => {
  switch (action.type) {
    case UPDATE_TRADINGPARTNER_LIST: {
      return {
        ...state,
        items: action.payload.items,
        pageNumber:action.payload.pageNumber,
        pageSize:action.payload.pageSize,
        totalCount:action.payload.totalCount
      };
    }
    default:
      return state;
  }
};
export default TradingPartnerReducer;

import Settings from './Setting';
import CommonReducer from './CommonReducer';
import Auth from './Auth';
import TransactionReducer from './TransactionReducer';
import CompiledScriptReducer from './CompiledScriptReducer';
import DocumentReducer from './DocumentReducer'
import ProcessReducer from './ProcessReducer'
import TradingPartnerReducer from './TradingPartnerReducer'
import XferTableReducer from './XferTableReducer';
import NotificationsReducer from './NotificationsReducer';
import EndpointReducer from './EndpointReducer';

const reducers = {
  settings: Settings,
  auth: Auth,
  common: CommonReducer,
  pagedTransactionList: TransactionReducer,
  pagedCompiledScriptList: CompiledScriptReducer,
  pagedDocumentList: DocumentReducer,
  pagedProcessList: ProcessReducer,
  pagedTradingPartnerList: TradingPartnerReducer,
  pagedXferTableList: XferTableReducer,
  pagedNotificationsList: NotificationsReducer,
  pagedEndpointList: EndpointReducer
};

export default reducers;

import {DocumentActionTypes, UPDATE_DOCUMENT_LIST} from '../../types/actions/Document.action';
import {PagedDocumentList} from '../../types/models/Document';

const INIT_STATE:PagedDocumentList = {
    pageNumber: 0,
    pageSize: 30,
    totalCount: 0,
    items: [] = []  
};

const DocumentReducer = (state = INIT_STATE, action: DocumentActionTypes) => {
  switch (action.type) {
    case UPDATE_DOCUMENT_LIST: {
      return {
        ...state,
        items: action.payload.items,
        pageNumber:action.payload.pageNumber,
        pageSize:action.payload.pageSize,
        totalCount:action.payload.totalCount
      };
    }
    default:
      return state;
  }
};
export default DocumentReducer;

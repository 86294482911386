import { XferTableActionTypes, UPDATE_XFERTABLE_LIST } from '../../types/actions/XferTable.action';
import { PagedXferTableList } from '../../types/models/XferTable';

const INIT_STATE: PagedXferTableList = {
  pageNumber: 0,
  pageSize: 30,
  totalCount: 0,
  items: [] = []
};

const XferTableReducer = (state = INIT_STATE, action: XferTableActionTypes) => {
  switch (action.type) {
    case UPDATE_XFERTABLE_LIST: {
      return {
        ...state,
        items: action.payload.items,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        totalCount: action.payload.totalCount
      };
    }
    default:
      return state;
  }
};
export default XferTableReducer;

import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import { fetchError, fetchStart, fetchSuccess, showMessage } from './Common';
import { AppActions } from '../../types';
import { Dispatch } from 'redux';
import { UPDATE_TRANSACTION_LIST } from '../../types/actions/Transaction.action';

// export const onRequestTransactions = (body: {
//   pageNumber: number;
//   pageSize: number;
// }) => {
//   return async (dispatch: Dispatch<AppActions>) => {
//     dispatch(fetchStart());
//     try {
//       const res = await jwtAxios.get(
//         'transactions?pageNumber=' +
//         body.pageNumber +
//         '&pageSize=' +
//         body.pageSize,
//       );
//       dispatch(fetchSuccess());
//       dispatch({
//         type: UPDATE_TRANSACTION_LIST,
//         payload: res.data.result,
//       });
//     } catch (err) {
//       console.log('error!!!!', err.response);
//       dispatch(fetchError(err.response));
//     }
//   };
// };

export const onRequestTransactions = (query: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await jwtAxios.get(
        `transactions${query}`
      );
      dispatch(fetchSuccess());
      dispatch({
        type: UPDATE_TRANSACTION_LIST,
        payload: res.data.result,
      });
    } catch (err) {
      console.log('error!!!!', err.response);
      dispatch(fetchError(err.response));
    }
  };
};

export const startManualTransaction = (content: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      let res = await jwtAxios.post('Processes/ManualTransaction', { content: content });
      console.log(res.data);
      if (res.data.isSuccessful) {
        dispatch(showMessage('Processed Successfully.'));
      }
      else {
        dispatch(fetchError(res.data.exceptionMessage));
      }
    } catch (err) {
      console.log('error!!!!', err.response);
      dispatch(fetchError(err.response));
    }
  };
};

export const restartTransaction = (transactionId: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      let res = await jwtAxios.post(`Document/Restart/${transactionId}`);
      console.log(res.data);
      if (res.data.isSuccessful) {
        dispatch(showMessage('Processed Successfully.'));
      }
      else {
        dispatch(fetchError(res.data.exceptionMessage));
      }
    } catch (err) {
      console.log('error!!!!', err.response);
      dispatch(fetchError(err.response));
    }
  };
};

import React from 'react';

export const settingsConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: ['/settings/compiledScripts/main'],
        component: React.lazy(() => import('./compiledScripts/main')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: ['/settings/compiledScripts/:id'],
        component: React.lazy(() => import('./compiledScripts/detail')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/settings/documents/main',
        component: React.lazy(() => import('./documents/main')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: ['/settings/documents/:id'],
        component: React.lazy(() => import('./documents/detail')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/settings/endpoints/main',
        component: React.lazy(() => import('./endpoints/main')),
      },
    ],
  },
  //{
    //auth: ['user'],
    //routes: [
      //{
        //path: ['/settings/endpoints/:id'],
        //component: React.lazy(() => import('./endpoints/detail')),
      //},
    //],
  //},
  {
    auth: ['user'],
    routes: [
      {
        path: '/settings/mappers/main',
        component: React.lazy(() => import('./mappers')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/settings/notifications/main',
        component: React.lazy(() => import('./notifications/main')),
      },
    ],
  },
  
   {
    auth: ['user'],
    routes: [
      {
        path: ['/settings/notifications/:id'],
        component: React.lazy(() => import('./notifications/detail')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/settings/processes/main',
        component: React.lazy(() => import('./processes/main')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: ['/settings/processes/:id'],
        component: React.lazy(() => import('./processes/detail')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/settings/tradingPartners/main',
        component: React.lazy(() => import('./tradingPartners/main')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: ['/settings/tradingPartners/:id'],
        component: React.lazy(() => import('./tradingPartners/detail')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/settings/xferTables/main',
        component: React.lazy(() => import('./xferTables/main')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: ['/settings/xferTables/:id'],
        component: React.lazy(() => import('./xferTables/detail')),
      },
    ],
  },
];
